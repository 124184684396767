<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          商品マスタ
        </CCardHeader>
        <vue-confirm-dialog></vue-confirm-dialog>
        <CCardBody>
          <div>
            <div class="mb-3 bg-gray-100 p-3">
              <CRow>
                <CCol sm="4">
                  <SelectComponent id="size" label="サイズ" end-point="sizes" @change="changeSize"
                                   :default-value="sizeId" placeholder="選択する"/>
                </CCol>
                <CCol sm="4">
                  <SelectComponent id="color" label="色" :default-options="colorOptions" @change="changeColor"
                                   :default-value="colorId" placeholder="選択する"/>
                </CCol>
                <CCol sm="4">
                  <SelectComponent id="season" label="シーズン" end-point="seasons" @change="changeSeasons"
                                   :default-value="season" placeholder="選択する"/>
                </CCol>
                <CCol sm="4">
                  <SelectComponent id="month" label="展開月" :default-options="businessMonthOptions"
                                   @change="changeBusinessMonth" :default-value="businessMonth"
                                   placeholder="選択する"/>
                </CCol>
                <CCol sm="4">
                  <SelectComponent id="year" label="年度" :default-options="businessYearOptions"
                                   @change="changeBusinessYear" :default-value="businessYear"
                                   placeholder="選択する"/>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="4">
                  <SelectComponent id="majorcategory" label="大分類" end-point="major-categories" @change="changeMajorCategory"
                                   :default-value="majorCategoryId" placeholder="選択する"
                  />
                </CCol>
                <CCol sm="4">
                  <SelectComponent id="middlecategory" label="中分類" end-point="middle-categories" @change="changeMiddleCategory"
                                   :default-value="middleCategoryId" placeholder="選択する"
                  />
                </CCol>
                <CCol sm="4">
                  <SelectComponent id="minorcategory" label="小分類" end-point="minor-categories" @change="changeMinorCategory"
                                   :default-value="minorCategoryId" placeholder="選択する"
                  />
                </CCol>
              </CRow>
<!--              <CategoryComponent :key="key" :default-value="categories" @change="changeCategories"-->
<!--                                 placeholder="選択する"/>-->
              <CRow>
                <CCol sm="10">
                  <a v-if="!isAutoSearch" style="margin-right: 5px">
                    <CButton color="primary" @click="filterData">検索</CButton>
                  </a>
                  <CButton color="danger" @click="resetFilterData">取り消す</CButton>
                </CCol>
              </CRow>
            </div>
            <CRow class="text-right mb-2" v-if="screen_key==this.SCREEN_KEY.LIST_ITEM_MASTERS">
              <CCol sm="12">
                <CButton color="primary" @click="handleCsvDownload" class="mr-2">CSVダウンロード</CButton>
                <CButton color="primary" @click="goToImportPage" class="mr-2" >CSVアップロード</CButton>
                <CButton @click="viewCreateItemMaster" type="submit" color="primary" class="mr-2">
                  商品登録
                </CButton>
              </CCol>
            </CRow>
            <div v-if="loading">
              <LoadingCompoment/>
            </div>
            <DataTable
                hover
                striped
                border
                :items="itemMasters"
                :fields="fields"
                :items-per-page="perPage"
                :active-page="page"
                :pagination-data="paginationData"
                @page-change="pageChange"
                @pagination-change="selectChangePerpage"
                :pagination="{ doubleArrows: false, align: 'center'}"
                :items-per-page-select="{
                                label: '表示件数',
                                values: [5, 10, 20, 30, 50, 100],
                            }"
            >
              <div v-if="screen_key === SCREEN_KEY.LIST_ITEM_MASTERS" slot="select_all-header">
                <CInputCheckbox
                    id="checkboxAll"
                    label=""
                    class="pb-3"
                    name="listSelected"
                    @update:checked="() => handleCheckAll()"
                    :checked="checkAll"
                />
              </div>
              <td v-if="screen_key === SCREEN_KEY.LIST_ITEM_MASTERS" slot="select_all" slot-scope="{item}">
                <div>
                  <CInputCheckbox
                      id="checkbox"
                      :key="item.id"
                      label=""
                      :value="item.item"
                      name="listSelected"
                      @update:checked="() => handleSelect(item)"
                      :checked="listSelected.includes(item.id)"
                  />
                </div>
              </td>
              <td v-if="screen_key === SCREEN_KEY.LIST_ITEM_MASTERS" slot="season" slot-scope="{item}">
                <div>{{ item.season && item.season.name ? item.season.name : '' }}</div>
              </td>
              <td slot="color" slot-scope="{item}">
                <div>{{ item.color_name || '' }}</div>
              </td>
              <td slot="size" slot-scope="{item}">
                <div>{{ item.size.name || '' }}</div>
              </td>
              <td slot="cost" slot-scope="{item}" class="text-right">
                <div> {{ item.cost_new || ''}}</div>
              </td>
              <td slot="price" slot-scope="{item}" class="text-right">
                <div> {{ item.price_new || ''}}</div>
              </td>
              <td slot="retail_price" slot-scope="{item}" class="text-right">
                <div> {{ item.retail_price_new || ''}}</div>
              </td>
              <td slot="select" slot-scope="{item}">
                <CButton
                    @click="() => onSelected(item, currentKey)"
                >選択
                </CButton>
              </td>
              <td slot="action" v-if="screen_key === SCREEN_KEY.LIST_ITEM_MASTERS" slot-scope="{item}"
                  class="text-center">
                <div>
                  <CButton @click="() => renderDetail(item)">
                    <CIcon name="cil-pencil"/>
                  </CButton>
                  <!--                                <CButton @click="() => handleConfirmDelete(item)" ><CIcon name="cil-recycle" /></CButton>-->
                </div>
              </td>
            </DataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>

import SelectComponent from "@/components/views/SelectComponent";
import {numberWithCommas, setOptionBusinessMonth, setOptionBusinessYear, setOptionColors} from "@/utils/utils";
import DataTable from "@/components/DataTable";
import ResourceApi from "../../api/resourceApi";
import ItemMaster from "@/views/item-masters/ItemMasterFields";
import {WAREHOUSUE, SCREEN_KEY} from '@/utils/constance';
import itemMasterApi from "@/api/itemMasterApi";
import Vue from "vue";

const apiItemMasters = new ResourceApi('item-masters');

export default {
  name: 'ItemMastersList',
  components: {DataTable, SelectComponent},
  props: {
    currentKey: Number,
    screen_key: String,
    isAutoSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      SCREEN_KEY: SCREEN_KEY,
      page: 1,
      perPage: 30,
      paginationData: {},
      businessMonth: '',
      businessYear: '',
      key: Math.random(),
      // categories: {
      //   major_category: '',
      //   middle_category: '',
      //   minor_category: '',
      // },
      majorCategoryId: '',
      middleCategoryId: '',
      minorCategoryId: '',
      businessYearOptions: [],
      businessMonthOptions: [],
      colorOptions: [],
      season: '',
      colorId: '',
      sizeId: '',
      fields: (this.screen_key == SCREEN_KEY.LIST_ITEM_MASTERS) ? ItemMaster.fieldsForList() : ItemMaster.fieldsForFilter(),
      itemMasters: [],
      loading: false,
      checkAll: false,
      listSelected: [],
    }
  },

  watch: {
    listSelected: function (newVal, oldVal) {
      let checkAll = true;
      this.itemMasters.map(s => {
        if (!newVal.includes(s.id)) {
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    },
    itemMasters: function (newVal, oldVal) {
      let checkAll = true;
      this.itemMasters.map(s => {
        if (!this.listSelected.includes(s.id)) {
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    }
  },
  created() {
    this.businessYearOptions = setOptionBusinessYear();
    this.businessMonthOptions = setOptionBusinessMonth();
    this.colorOptions = setOptionColors();
    this.setParams()
    let params = this.getParams()
    this.getItemMasters(params)
  },

  beforeRouteEnter (to, from, next) {
    if (from.name !== "Update Item Master") {
      sessionStorage.removeItem('itemMasters')
    }
    next()
  },

  methods: {
    getParams(param) {
      let params = {
        page: this.page,
        per_page: this.perPage,
        size_id_equal: this.sizeId,
        color_id_equal: this.colorId,
        season_id_equal: this.season,
        business_year_equal: this.businessYear,
        month_equal: this.businessMonth,
        major_category_id_equal: this.majorCategoryId,
        middle_category_id_equal: this.middleCategoryId,
        minor_category_id_equal: this.minorCategoryId,
      }
      return {...params, ...param}
    },
    setParams() {
      let params = sessionStorage.getItem('itemMasters') ? JSON.parse(sessionStorage.getItem('itemMasters')) : {}
      if(Object.keys(params).length) {
        this.page = params.page
        this.perPage = params.per_page
        this.sizeId = params.size_id_equal
        this.colorId = params.color_id_equal
        this.season = params.season_id_equal
        this.businessYear = params.business_year_equal
        this.businessMonth = params.month_equal
        this.majorCategoryId = params.major_category_id_equal
        this.middleCategoryId = params.middle_category_id_equal
        this.minorCategoryId = params.minor_category_id_equal
      }
    },
    focusMe(e) {
      console.log(e) // FocusEvent
    },
    pageChange(val) {
      this.page = val;
      let params = this.getParams({page: val})
      this.getItemMasters(params);
    },
    handleConfirmDelete(user) {
      let message = 'ユーザー情報を削除しますが宜しいでしょうか？'
      this.$confirm(
          {
            title: '確認',
            message: message,
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                // ... do something
                this.handleDeleteItem(user)
              }
            }
          }
      )
    },

    handleDeleteItem(item) {
      apiItemMasters.delete(item).then(response => {
        Vue.$toast.success('Deleted success');
        this.getItemMasters();
      }).catch(error => {
        // Vue.$toast.error('Error')
      }).finally(() => {
        this.loading = false
      })
    },

    handleCsvDownload() {
      this.loading = true
      let list = this.listSelected
      let listStr = list.join()
      let params = this.getParams({list_id_str: listStr})
      itemMasterApi.csvDownload(params).then(response => {
        Vue.$toast.success('ダウンロード成功')
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let timeCurrent = new Date();
        fileLink.setAttribute('download', 'item-master-' + timeCurrent.toLocaleString() + '.csv');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        Vue.$toast.error('ダウンロードに失敗しました')
      }).finally(() => {
        this.loading = false
      })
    },
    renderDetail(item) {
      this.$router.push({path: `item-masters/${item.id}`})
    },
    getItemMasters(query) {
      this.loading = true
      sessionStorage.setItem('itemMasters', JSON.stringify(query))
      apiItemMasters.index(query).then(response => {
        this.itemMasters = response.data.data;
        this.itemMasters.map(item => {
          item.cost_new = numberWithCommas(item.cost)
          item.price_new = numberWithCommas(item.price)
          item.retail_price_new = numberWithCommas(item.retail_price)
          item.color_name = item.color.color_code + ':' + item.color.name
        })
        let suggestions = [];
        response.data.data.map(s => {
          let i = {
            value: s.id,
            label: s.name,
          }
          suggestions.push(i)
        })
        this.paginationData = response.data;
        if (this.currentKey || this.currentKey == 0) {
          this.stocks[this.currentKey].suggestions = suggestions;
        }
      }).catch(error => {
      }).finally(() => {
        this.loading = false
      })
    },
    selectChangePerpage(val) {
      this.perPage = val ? parseInt(val) : '';
      let params = this.getParams({per_page: val})
      this.getItemMasters(params);
    },
    changeSeasons(val) {
      this.season = val ? parseInt(val) : ''
      let params = this.getParams({season_id_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    changeSize(val) {
      this.sizeId = val ? parseInt(val) : ''
      let params = this.getParams({size_id_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    changeColor(val) {
      this.colorId = val ? parseInt(val) : ''
      let params = this.getParams({color_id_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    changeBusinessYear(val) {
      this.businessYear = val ? parseInt(val) : ''
      let params = this.getParams({business_year_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    changeBusinessMonth(val) {
      this.businessMonth = val ? parseInt(val) : ''
      let params = this.getParams({month_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    changeMajorCategory(val) {
      this.majorCategoryId = val ? parseInt(val) : ''
      let params = this.getParams({major_category_id_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    changeMiddleCategory(val) {
      this.middleCategoryId = val ? parseInt(val) : ''
      let params = this.getParams({middle_category_id_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    changeMinorCategory(val) {
      this.minorCategoryId = val ? parseInt(val) : ''
      let params = this.getParams({minor_category_id_equal: val})
      if (this.isAutoSearch) {
        this.getItemMasters(params)
      }
    },
    // changeCategories(val) {
    //   let valNew = {
    //     major_category_id_equal: val.major_category ? val.major_category : '',
    //     middle_category_id_equal: val.middle_category ? val.middle_category : '',
    //     minor_category_id_equal: val.minor_category ? val.minor_category : '',
    //   }
    //   this.categories = valNew;
    //
    //   let params = this.getParams(valNew)
    //   if (this.isAutoSearch) {
    //     this.getItemMasters(params)
    //   }
    //   this.categories = val
    // },
    resetFilterData() {
      this.sizeId = '';
      this.colorId = '';
      this.season = '';
      this.businessMonth = '';
      this.businessYear = '';
      // this.categories = {};
      this.minorCategoryId = '';
      this.majorCategoryId = '';
      this.middleCategoryId = '';
      this.key = Math.random()
      let params = this.getParams()
      this.getItemMasters(params);
    },
    onSelected(item, k) {
      this.$emit('select-item', {item, k});
      this.resetFilterData()
    },
    viewCreateItemMaster() {
      this.$router.push({path: `item-masters/create`})
    },
    handleCheckAll() {
      let listSelected = this.listSelected
      if (this.checkAll) {
        this.itemMasters.map(s => {
          listSelected = listSelected.filter(l => s.id !== l)
        })
      } else {
        this.itemMasters.map(s => {
          listSelected.push(s.id)
        })
      }
      this.listSelected = listSelected;
    },
    handleSelect(item) {
      if (this.listSelected.includes(item.id)) {
        this.listSelected = this.listSelected.filter(s => s !== item.id)
      } else {
        this.listSelected.push(item.id)
      }
    },
    filterData() {
      let params = this.getParams();
      this.getItemMasters(params);
    },
    goToImportPage() {
      this.$router.push({ path: `item-masters/import`})
    }
  }
}
</script>