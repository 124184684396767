
const ItemMaster = {

    fieldsForFilter() {
        return [
            {key: 'name', label: '品名'},
            {key: 'color', label: 'カラー'},
            {key: 'size', label: 'サイズ'},
            {key: 'select', label: ''},
        ]
    },

    fieldsForList() {
        return [
            {key: 'select_all', label: ''},
            {key: 'id', label: 'id'},
            {key: 'name', label: '品名'},
            {key: 'size', label: 'サイズ'},
            {key: 'color', label: 'カラー'},
            {key: 'cost', label: '仕入原価'},
            {key: 'price', label: '販売価格'},
            {key: 'retail_price', label: 'プロパー上代'},
            {key: 'item_code', label: '品番コード'},
            {key: 'business_year', label: '年度'},
            {key: 'month', label: '展開月'},
            {key: 'season', label: 'シーズン'},
            { key: 'action', label: '' },
        ]
    }
}

export default ItemMaster