import request from './request'

const itemMasterApi = {
  csvDownload(query) {
    return request({
      url: 'item-masters/export',
      method: 'get',
      params: query,
      responseType: 'blob'
    })
  },
  upload(data) {
    return request({
      url: 'item-masters/upload',
      method: 'post',
      data: data,
    })
  },
  import(data, progress) {
    return request({
      url: 'item-masters/import',
      method: 'post',
      data: data,
      timeout: 3600000,
      onUploadProgress: progress
    })
  },
}
export default itemMasterApi
